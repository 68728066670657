import { render, staticRenderFns } from "./IncidentViewClient.vue?vue&type=template&id=44e32d3a&"
import script from "./IncidentViewClient.vue?vue&type=script&lang=js&"
export * from "./IncidentViewClient.vue?vue&type=script&lang=js&"
import style0 from "./IncidentViewClient.vue?vue&type=style&index=0&id=44e32d3a&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports