export default {
  header: 'Devices',
  tableHeaders: {
    formsCount: 'Forms Count',
    dispatchId: 'Dispatch ID',
    lastSync: 'Last Sync',
    inventoryNum: 'Inventory #',
    iosVersion: 'iOS Ver.',
    appVersion: 'App Ver.',
    template: 'Template',
    blsLocked: 'BLS Locked?',
    unitType: 'Unit Type',
    isAPDevice: 'AP Device',
  },
  filters: {
    search: 'Search',
    status: 'Status',
    type: 'Unit Type',
    all: 'All',
    statuses: {
      all: 'All',
      active: 'Active',
      deleted: 'Inactive',
    },
    isAPDevice: 'AP Devices',
  },
  editPopup: {
    headerEdit: 'Edit Device',
    headerNew: 'Add Device',
    udid: 'UDID',
    dispatchId: 'Dispatch ID',
    unitType: 'Unit Type',
    inventoryNumber: 'Device Inventory #',
    description: 'Description',
    blsAllowed: 'Enforce BLS locking between devices',
    isAPDevice: 'AP Device',
    selectClient: 'Client',
  },
  unfinishedFormsPopup: {
    header: 'Reports on Device',
    tableHeaders: {
      sequenceNumber: 'SequenceNumber',
      incidentDate: 'IncidentDate',
      lastName: 'PatientLastName',
      age: 'PatientAge',
      lastModified: 'DateLastModified',
      tms: 'TMs',
    },
  },
  deleteConfirmationTitle: 'Delete device',
  deleteConfirmationText:
    'Do you really want to delete device: {dispatchId} (Inventory #: {inventoryNumber})?',
  deactivateConfirmationTitle: 'Deactivation device',
  deactivateConfirmationText:
    'Do you really want to deactivate device: {dispatchId} (Inventory #: {inventoryNumber})?',

  restoreConfirmationTitle: 'Restore device',
  restoreConfirmationText:
    'Do you want to restore device: {dispatchId} (Inventory #: {inventoryNumber})?',
};
