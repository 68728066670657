<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="items"
      :options.sync="pagination"
      :server-items-length="totalItems"
      :loading="loading"
      :footer-props="{
        itemsPerPageOptions: [10,25,50]
      }"
      fixed-header
      class="unsubmitted-incidents-table header-nowrap"
      no-data-text="No data available"
      no-results-text="No data available"
    >
      <template v-slot:item.sequenceNumber="{ item }">
        <a
          :title="item.clientName"
          @click="goToIncident(item)"
        >
          {{ item.sequenceNumber }}
        </a>
      </template>
      <template v-slot:item.incidentDateTime="{ item }">
        {{ item.incidentDateTime | datetime }}
      </template>
      <template v-slot:item.createdAt="{ item }">
        {{ item.createdAt | datetimeToClientTimezone }}
      </template>
      <template v-slot:item.exportedAt="{ item }">
        {{ item.exportedAt | datetimeToClientTimezone }}
      </template>
      <template v-slot:item.error="{ item }">
        <a
          v-if="item.errorExplanation"
          @click="$refs.errorExplanationPopup.show(item.errorExplanation, item.response)"
        >
          {{ item.error }}
        </a>
        <span v-else>{{ item.error }}</span>
      </template>
      <template v-slot:item.ignoreNemsisExport="{ item }">
        <span class="red--text">{{ item.ignoreNemsisExport ? 'Ignored' : '' }}</span>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn
            class="float-right mr-4"
            icon
            title="Submit"
            :loading="item.submitting"
            @click="submit(item)"
        >
          <v-icon color="grey darken-2">mdi-file-upload-outline</v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <error-explanation-popup ref="errorExplanationPopup"/>
  </div>
</template>

<script>
import _ from 'lodash';
import impersonateMixins from '@/mixins/impersonateMixins';
import nemsisExport from '@/api/nemsisExport';
import ErrorExplanationPopup from '@/components/NemsisExport/ErrorExplanationPopup';
import { mapActions } from 'vuex';
import { SHOW_SNACKBAR } from '@/store/actions';

export default {
  name: 'UnsubmittedIncidentsTable',
  components: { ErrorExplanationPopup },

  mixins: [impersonateMixins],

  props: {
    type: {
      type: String,
      required: true,
    },
    filters: {
      type: Object,
      required: true,
    },
    usesClientsFilter: {
      default: true,
      type: Boolean,
    },
    useImpersonate: {
      default: true,
      type: Boolean,
    },
  },
  data() {
    return {
      debounce: null,
      pagination: {
        sortDesc: [true],
        sortBy: ['incidentDateTime'],
        page: 1,
        itemsPerPage: 25,
        totalItems: 0,
      },
      items: [],
      totalItems: 0,
      loading: false,
      headers: [
        {
          text: 'Sequence Number',
          value: 'sequenceNumber',
        },
        {
          text: 'Incident Number',
          value: 'incidentNumber',
        },
        {
          text: 'Incident DateTime',
          value: 'incidentDateTime',
        },
        {
          text: 'Last Attempted At',
          value: 'exportedAt',
        },
        {
          text: 'Error',
          value: 'error',
          sortable: false,
        },
        {
          text: 'Ignored',
          value: 'ignoreNemsisExport',
        },
        {
          text: 'Attempts',
          value: 'attempts',
        },
        {
          text: '',
          value: 'actions',
          sortable: false,
        },
      ],
    };
  },

  watch: {
    /**
     * Watch pagination options and update pagination
     */
    pagination() {
      this.fetchDataDebounce();
    },
  },
  mounted() {
    this.fetchDataDebounce();
  },
  methods: {
    ...mapActions({
      showSnackbar: SHOW_SNACKBAR,
    }),
    async fetchData() {
      const params = {
        page: this.pagination.page,
        perPage: this.pagination.itemsPerPage,
        orderBy: this.pagination.sortBy[0],
        sortOrder: this.pagination.sortDesc[0] ? 'desc' : 'asc',
        type: this.type,
        ...this.filters,
      };
      try {
        this.loading = true;
        const response = await nemsisExport.getUnsubmittedIncidents(params);
        this.items = response.data;
        this.totalItems = response.total;
      } finally {
        this.loading = false;
      }
    },

    fetchDataDebounce() {
      if (!this.debounce) {
        const that = this;
        this.debounce = _.debounce(() => {
          that.fetchData();
        }, 300);
      }
      this.debounce();
    },

    /**
     * Impersonate user to client and go to incident view page.
     *
     * @param {Object} incident - Incident data object.
     */
    goToIncident(incident) {
      if (this.useImpersonate) {
        this.impersonateToIncident(
          incident.clientId,
          incident.id,
        );
      } else {
        const url = `/incidents/${incident.id}`;
        const win = window.open(url, '_blank');
        win.focus();
      }
    },

    /**
     * Submit incident.
     *
     * @param {Object} incident - Incident data object.
     */
    async submit(incident) {
      const itemObj = incident;
      try {
        itemObj.submitting = true;
        await nemsisExport.submitIncident(incident.id, this.type);
        this.showSnackbar(`Incident ${incident.sequenceNumber} was scheduled to export.`);
      } catch (e) {
        if (e.response.status === 404) {
          this.showSnackbar({ title: `The export setting of the client ${incident.clientName} not found.`, color: 'red' });
        } else {
          this.showSnackbar({ title: `Error of submitting the ${incident.sequenceNumber} incident.`, color: 'red' });
        }
      } finally {
        itemObj.submitting = false;
      }
    },
  },
};
</script>

<style lang="scss" module>
.actions {
  width: 20%;
}

.deleted {
  background-color: $red-light;
}
</style>
<style lang="scss">
.unsubmitted-incidents-table {
  .v-data-table__wrapper {
    height: calc(100vh - 330px);
  }
  thead {
    th {
      z-index: 1 !important;
    }
  }
}
</style>
