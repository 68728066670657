<template>
  <div class="incident-ap-forms-table">
    <v-data-table
        :headers="headers"
        :items="items"
        :options.sync="pagination"
        :server-items-length="totalItems"
        :loading="loading"
        :footer-props="{
          itemsPerPageOptions: [10,25,50]
        }"
        hide-default-header
        fixed-header
        no-data-text="No AP forms match the specified search criteria."
    >
      <template v-slot:header="{ props: { headers } }">
        <thead
          class="v-data-table-header"
        >
          <tr
            class="fixed sections"
          >
            <th
                class="text-center sequence-number"
                rowspan="2"
            >
              SequenceNumber
            </th>
            <th
                class="text-center"
                :colspan="8"
            >
              Incident Info
            </th>
          </tr>
          <tr
            class="fields"
          >
            <th
                v-for="header in headers.filter(function (value) {
                  return value.hidden !== true;
                })"
                :key="header.text"
                :class="[
                  header.sortable ? 'sortable' : '',
                  header.value === pagination.sortBy[0] ? 'active' : '',
                  header.align === 'left' ? 'text-left' : '',
                  pagination.sortDesc[0] ? 'desc': 'asc'
                ]"
                :style="header.width ? 'min-width: ' + header.width + 'px;' : null"
                class="field-col text-left"
                @click="header.sortable ? changeSort(header.value) : null"
            >
              <span v-html="header.text"></span>
              <v-icon
                v-if="header.sortable"
                class="v-data-table-header__icon"
                small
                >mdi-arrow-up</v-icon>
            </th>
          </tr>
        </thead>
      </template>
      <template v-slot:body.append>
        <tr></tr>
      </template>

      <template v-slot:item.sequenceNumber="{ item }">
        <div class="first-col">
          <v-btn
              icon
              class="float-left mr-1"
              @click="showApForm(item)"
          >
            <v-icon>mdi-file-image</v-icon>
          </v-btn>

          <span class="float-left mt-2">
            <a
                href="javascript:;"
                @click="$emit('clickIncident', item)"
              >
                <b>
                  {{ item.sequenceNumber }}
                </b>
            </a>
          </span>

          <div>
            <v-menu>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    icon
                    v-bind="attrs"
                    class="float-right"
                    v-on="on"
                >
                  <v-icon>mdi-dots-horizontal</v-icon>
                </v-btn>
              </template>

              <v-list dense>
                <v-list-item @click="showApForm(item)">
                  <v-list-item-icon>
                    <v-icon>
                      mdi-file-eye-outline
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>View</v-list-item-title>
                </v-list-item>
                <v-list-item @click="$emit('clickIncident', item)">
                  <v-list-item-icon>
                    <v-icon>
                      mdi-pencil
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Edit</v-list-item-title>
                </v-list-item>
                <v-list-item
                    v-if="$can('convertToRegular', 'incident')"
                    @click="convertToRegular(item)"
                >
                  <v-list-item-icon>
                    <v-icon>
                      mdi-clipboard-flow-outline
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Convert to Regular</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </div>
      </template>
      <template  v-slot:item.incidentDateTime="{ item }">
        {{ item.incidentDateTime | datetime }}
      </template>
      <template  v-slot:item.apAssessmentAt="{ item }">
        {{ item.apAssessmentAt | datetime }}
      </template>
      <template v-slot:item.age="{ item }">
        {{ item.age }}<span v-if="item.ageUnit !== null"> {{ item.ageUnit }}</span>
      </template>
      <template v-slot:item.complaints="{ item }">
        <span v-if="item.complaints.length !== 0"> {{ item.complaints.join(', ') }}</span>
      </template>
    </v-data-table>

    <view-incident-popup
        ref="viewIncidentPopup"
        :show-flag-btn="false"
        :show-nav-btn="false"
    />
  </div>
</template>

<script>
import _ from 'lodash';
import incidents from '@/api/incidents';
import ViewIncidentPopup from '@/components/Incidents/ViewIncidentPopup';
import INCIDENT_FIELDS from '@/enums/incidentFields';

export default {
  name: 'IncidentApFormsTable',
  components: { ViewIncidentPopup },

  props: {
    completed: {
      type: Boolean,
      required: true,
    },
    filters: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      debounce: null,
      pagination: {
        sortDesc: [true],
        sortBy: ['incidentDateTime'],
        page: 1,
        itemsPerPage: 25,
        totalItems: 0,
      },
      items: [],
      totalItems: 0,
      loading: true,
      headers: [
        {
          text: 'SequenceNumber',
          value: 'sequenceNumber',
          hidden: true,
        },
        {
          text: 'IncidentDate',
          value: 'incidentDateTime',
          sortable: true,
        },
        {
          text: 'AP Assessment At',
          value: 'apAssessmentAt',
          sortable: true,
        },
        {
          text: 'IncidentNumber',
          value: 'incidentNumber',
          sortable: true,
        },
        {
          text: 'AP Last Name',
          value: 'apLastName',
          sortable: true,
        },
        {
          text: 'Pt. Last Name',
          value: 'ptLastName',
          sortable: true,
        },
        {
          text: 'Age',
          value: 'age',
          sortable: false,
          width: 90,
        },
        {
          text: 'Gender',
          value: 'sex',
          sortable: true,
        },
        {
          text: 'Chief Complaint(s)',
          value: 'complaints',
          sortable: false,
        },
      ],
    };
  },

  mounted() {
    this.fetchDataDebounce();
  },

  watch: {
    /**
     * Watch pagination options and update pagination props.
     */
    pagination() {
      this.fetchDataDebounce();
    },
  },
  methods: {
    async fetchData() {
      const params = {
        page: this.pagination.page,
        perPage: this.pagination.itemsPerPage,
        orderBy: this.pagination.sortBy[0],
        sortOrder: this.pagination.sortDesc[0] ? 'desc' : 'asc',
        completed: this.completed ? 1 : 0,
        ...this.filters,
      };
      try {
        this.loading = true;
        const response = await incidents.fetchApIncidents(params);
        this.items = response.data;
        this.totalItems = response.total;
        this.$emit('returnTotal', this.totalItems);
      } finally {
        this.loading = false;
      }
    },

    search() {
      this.pagination.page = 1;
      this.fetchDataDebounce();
    },

    fetchDataDebounce() {
      if (!this.debounce) {
        const that = this;
        this.debounce = _.debounce(() => {
          that.fetchData();
        }, 300);
      }
      this.debounce();
    },

    changeSort(column) {
      const pagination = _.clone(this.pagination);
      if (pagination.sortBy[0] === column) {
        if (pagination.sortDesc[0] === true) {
          pagination.sortBy = [];
          pagination.sortDesc = [];
        } else {
          pagination.sortDesc = [true];
        }
      } else {
        pagination.sortBy = [column];
        pagination.sortDesc = [false];
      }
      this.pagination = pagination;
    },

    showApForm(item) {
      const incident = {
        id: item.id,
      };
      incident[INCIDENT_FIELDS.SEQUENCE_NUMBER] = item.sequenceNumber;
      const options = {
        showViewIncidentLink: this.$can('seeViewIncidentLink', 'apForms'),
        showActions: true,
        showNonRegularActions: false,
        showNarcoticRequest: false,
      };
      this.$refs.viewIncidentPopup.show(
        incident,
        options,
        item.apFormIndex,
      );
    },
    async convertToRegular(item) {
      await incidents.convertApToRegular(item.id);
      this.fetchData();
    },
  },
};
</script>

<style lang="scss">
  .incident-ap-forms-table {
    .v-data-table__wrapper {
      height: calc(100vh - 320px);
    }
    .sequence-number {
      z-index: 100 !important;
    }
    .field-col {
      white-space: nowrap;
    }
    table > tbody > tr > td:nth-child(1),
    table > thead > tr.fixed > th:nth-child(1) {
      position: sticky !important;
      position: -webkit-sticky !important;
      left: 0;
      z-index: 1;
      background: #f5f5f5;
      border-right: 1px solid rgba(0, 0, 0, 0.12);
    }
    table > thead > tr.fixed > th:nth-child(1) {
      z-index: 3;
      width: 240px;
    }
    table > thead tr.sections th {
      border-right: 1px solid rgba(0, 0, 0, 0.12);
      background: #f5f5f5 !important;
      height: 30px;
    }
    table > thead tr.fields th{
      top: 30px !important;
    }
    table > thead tr.sections th:nth-last-child(1) {
      border-right: none;
    }
    .first-col {
      width: 240px;
      white-space: nowrap;
      button {
        margin-left: 10px;
        i {
          cursor: pointer;
        }
      }
    }

  }
</style>
