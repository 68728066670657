<template>
  <div class="agency-overview">
    <v-tabs v-model="activeTab">
      <v-tab>agency-overview</v-tab>
      <v-tab>users</v-tab>
      <v-tab-item eager>
        <user-list
            ref="userList"
            @showUser="showUser"
            @addPeriodClick="showAddEditPeriodDialog"
        />
      </v-tab-item>
      <v-tab-item eager>
        <view-user
            ref="viewUser"
            @goBack="goBackToUserList"
            @viewEntry="viewEntry"
            @addPeriodClick="showAddEditPeriodDialog"
        />
      </v-tab-item>
    </v-tabs>
    <edit-period-popup
      ref="editPeriodPopup"
      @saved="savedPeriodHandler"
    />
  </div>
</template>
<script>

import UserList from '@/components/CeEntry/UserList';
import EditPeriodPopup from '@/components/CeEntry/EditPeriodPopup';
import ViewUser from '@/components/CeEntry/ViewUser';
import ROUTES from '@/enums/routes';

export default {
  name: 'AgencyOverview',
  components: {
    ViewUser,
    EditPeriodPopup,
    UserList,
  },
  data() {
    return {
      activeTab: 0,
    };
  },
  mounted() {
    const userId = this.$route.params.id;
    if (userId) {
      this.showUserById(userId);
    }
  },
  methods: {
    updateData() {
      this.$refs.userList.fetchData();
    },
    resetView() {
      this.activeTab = 0;
    },
    goBackToUserList() {
      this.activeTab = 0;
      const to = {
        name: ROUTES.CE_ENTRY_AGENCY_OVERVIEW,
      };
      const link = this.$router.resolve(to).href;
      window.history.replaceState({}, null, link);
    },
    showUserById(userId) {
      this.$refs.viewUser.loadUsingUserId(userId);
      this.activeTab = 1;
    },
    showUser(userData) {
      this.$refs.viewUser.loadUsingUserData(userData);
      this.activeTab = 1;
      const to = {
        name: ROUTES.CE_ENTRY_AGENCY_OVERVIEW_USER,
        params: {
          id: userData.id,
        },
      };
      const link = this.$router.resolve(to).href;
      window.history.replaceState({}, null, link);
    },
    viewEntry(entry) {
      this.$emit('viewEntry', entry);
    },
    showAddEditPeriodDialog({
      userId,
      licenseTypeId,
      period,
      currentPeriod,
    }) {
      this.$refs.editPeriodPopup.show(userId, licenseTypeId, period, currentPeriod);
    },
    savedPeriodHandler() {
      this.$refs.userList.fetchData();
      if (this.activeTab === 1) {
        this.$refs.viewUser.updateData();
      }
    },
  },
};
</script>
<style>
.agency-overview {
  .v-tabs-bar {
    display: none;
  }
  .agency-overview-userlist {
    .v-tabs-bar {
      display: flex;
    }
  }
}

</style>
