<template>
  <v-layout
    align-center
    justify-center
  >
    <v-flex
      xs12
      sm8
      md4
    >
      <v-card class="elevation-20">
        <v-toolbar
          dark
          color="primary"
        >
          <v-toolbar-title>Cad Monitoring</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-form @submit.prevent="checkSiteCodeRequest">
            <v-alert
              :value="errorAlert"
              type="error"
              outlined
              transition="scale-transition"
            >
              {{ errorMessage }}
            </v-alert>
            <v-alert
              :value="siteCodeNotFoundAlert"
              type="error"
              outlined
              transition="scale-transition"
            >
              CAD Provider does not exist for that SiteCode
            </v-alert>

            <v-text-field
              ref="sitecode"
              v-model="sitecode"
              v-validate="'required'"
              :error-messages="errors.collect('sitecode')"
              prepend-icon="domain"
              name="sitecode"
              label="Site Code"
              type="text"
            />
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn
            :loading="loading"
            :disabled="loading"
            color="primary"
            @click="checkSiteCodeRequest"
          >
            Go to Monitoring
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import { mapState } from 'vuex';
import { checkProviderExists } from '@/api/cadMonitoring';
import errorAlertMixins from '@/mixins/errorAlertMixins';
import siteCodeMixins from '@/mixins/siteCodeMixins';
import baseUrl from '@/baseUrl';

export default {
  name: 'SiteCode',
  mixins: [errorAlertMixins, siteCodeMixins],
  data() {
    return {
      siteCodeNotFoundAlert: false,
      loading: false,
      dictionary: {
        attributes: {
          sitecode: 'Site Code',
        },
      },
    };
  },
  computed: {
    ...mapState('cadMonitoring', ['forceShowProviders']),
  },
  mounted() {
    this.$refs.sitecode.focus();
    this.$validator.localize('en', this.dictionary);
  },
  methods: {
    /**
     * Check provider exists by current site code,
     * if exists then go to monitoring.
     */
    checkSiteCodeRequest() {
      this.$validator.validateAll().then(isValid => {
        if (!isValid) {
          return;
        }
        this.loading = true;
        this.siteCodeNotFoundAlert = false;
        this.hideError();
        checkProviderExists()
          .then(response => {
            if (response.exists) {
              window.location.replace(
                `${baseUrl(this.sitecode)}/Utilities/CadMonitoring`,
              );
            } else {
              this.siteCodeNotFoundAlert = true;
            }
            this.loading = false;
          })
          .catch(error => {
            this.loading = false;
            this.showError();
          });
      });
    },
  },
};
</script>
