<template>
  <v-toolbar
      flat
      class="incidents-ap-forms-toolbar"
      height="auto"
  >
    <v-layout
        row
        wrap
    >
      <v-toolbar-items class="mb-2 mt-2">
        <date-range-picker-with-periods
            v-model="dateRange"
            :label="filtersModel.rangeBy === 'incidentDate'
              ? 'Incident Date'
              : 'Assessment Date'"
            class="mr-5"
            position="right"
            @closed="datePickerCloseHandler"
        >
          <div
              slot="header"
              :class="[$style['date-header']]"
          >
            <v-radio-group
                v-model="dateRangeFieldTmp"
                row
            >
              <v-radio
                  label="Incident Date"
                  value="incidentDate"
              />
              <v-radio
                  label="Assessment Date"
                  value="assessmentDate"
              />
            </v-radio-group>
          </div>
        </date-range-picker-with-periods>
      </v-toolbar-items>
      <v-toolbar-items class="mb-2 mt-2">
        <v-text-field
            v-model="filtersModel.sequenceNumber"
            label="Sequence Number"
            hide-details
            class="mr-5"
        />
      </v-toolbar-items>
      <v-toolbar-items class="mb-2 mt-2">
        <v-text-field
            v-model="filtersModel.incidentNumber"
            label="Incident Number"
            hide-details
            class="mr-5"
        />
      </v-toolbar-items>
      <v-toolbar-items class="mb-2 mt-2">
        <v-text-field
            v-model="filtersModel.apLastName"
            label="AP Last Name"
            hide-details
            class="mr-5"
        />
      </v-toolbar-items>
      <v-toolbar-items class="mb-2 mt-2">
        <v-text-field
            v-model="filtersModel.ptLastName"
            label="Pt. Last Name"
            hide-details
            class="mr-5"
        />
      </v-toolbar-items>
    </v-layout>

    <v-btn
        class="mr-3"
        color="primary"
        @click="$emit('clickSearch')"
    >
      Search
    </v-btn>
  </v-toolbar>
</template>

<script>
import { mapActions } from 'vuex';
import { SHOW_SNACKBAR } from '@/store/actions';
import DateRangePickerWithPeriods from '@/components/DateRangePickerWithPeriods';
import _ from 'lodash';

export default {
  name: 'IncidentApFormsToolbar',
  components: { DateRangePickerWithPeriods },

  props: {
    filters: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dateRange: null,
      dateRangeFieldTmp: 'incidentDate',
      filtersModel: this.filters,
    };
  },

  watch: {
    dateRange: {
      handler() {
        const filters = _.cloneDeep(this.filtersModel);
        if (this.dateRange && this.dateRange.length === 2) {
          const [dateFrom, dateTo] = this.dateRange;
          filters.dateRangeFrom = dateFrom;
          filters.dateRangeTo = dateTo;
        }
        if (!this.dateRange || this.dateRange.length !== 2) {
          filters.dateRangeFrom = null;
          filters.dateRangeTo = null;
        }
        this.filtersModel = filters;
      },
      deep: true,
    },
    filtersModel: {
      handler() {
        this.$emit('update:filters', this.filtersModel);
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions({
      showSnackbar: SHOW_SNACKBAR,
    }),

    /**
     * Handler of datetime range picker close action.
     *
     * @param {Boolean} applied - If closed by applied button
     * @param {Boolean} cancelled - If closed by cancel button
     */
    datePickerCloseHandler(applied, cancelled) {
      if (this.filters.rangeBy !== this.dateRangeFieldTmp && !cancelled) {
        this.filters.rangeBy = this.dateRangeFieldTmp;
      }
      if (this.filters.rangeBy !== this.dateRangeFieldTmp && cancelled) {
        this.dateRangeFieldTmp = this.filters.rangeBy;
      }
    },
  },
};
</script>

<style lang="scss">
.incidents-ap-forms-toolbar {
  z-index: 101;
}
</style>

<style lang="scss" module>
.date-header {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  border-bottom: thin solid #eee;
  padding-top: 15px;
  padding-left: 15px;
}
</style>
