import { mapActions } from 'vuex';
import { CHANGE_SITECODE } from '@/store/actions';
import baseUrl from '@/baseUrl';
import USER_ROLES from '@/enums/userRoles';
import CLIENT_SITE_TYPES from '@/enums/clientSiteTypes';

export default {
  computed: {
    sitecode: {
      get() {
        return this.$store.state.sitecode;
      },
      set(value) {
        this.CHANGE_SITECODE(value);
      },
    },
  },
  methods: {
    ...mapActions([CHANGE_SITECODE]),
    afterLogin(user) {
      let url = '/incidents';
      if (this.sitecode === 'admin') {
        url = '/dashboard';
      } else if (this.sitecode === 'hospital') {
        url = '/incidents';
      } else if (this.$store.state.siteInfo.clientSiteType === CLIENT_SITE_TYPES.CE_ENTRY) {
        url = '/ce-home';
      } else if (user.userTypeId === USER_ROLES.CLIENT_ADMIN
              || user.userTypeId === USER_ROLES.SUPER_ADMIN
              || user.userTypeId === USER_ROLES.CLIENT_QA_USER
      ) {
        url = '/dashboard';
      } else if (user.userTypeId === USER_ROLES.CLIENT_AP_USER) {
        url = '/incidents/ap-forms';
      }

      const currentUrl = new URL(window.location.href);
      let redirectUrl = currentUrl.searchParams.get('redirect');
      if (
        redirectUrl
          && redirectUrl.indexOf(baseUrl(this.sitecode)) === -1
      ) {
        redirectUrl = null;
      }
      const redirect = redirectUrl || `${baseUrl(this.sitecode)}${url}`;
      localStorage.setItem('justLoggedIn', 1);
      window.location.replace(redirect);
    },
  },
};
