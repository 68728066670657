<template>
  <div>
    <v-card class="white elevation-3 ma-4">
      <search
        ref="search"
        :type="type"
        :loading="loading"
        :searching="searching"
        :show-save-monitor="!!entity.monitor"
        :show-create-monitor="type === searchTypes.REGULAR && $can('create', 'monitor')"
        :can-find-duplications="type === searchTypes.REGULAR"
        @searchBasic="searchBasic"
        @searchAdvanced="searchAdvanced"
        @save-monitor="saveMonitor"
        @create-monitor="createMonitor"
      />
    </v-card>

    <v-card
        id="totalResults"
        class="white elevation-3 ma-4"
    >
      <search-result-tab
        ref="incidents"
        :type="type"
        :external-loading="loading"
        @add-flag="addFlag"
        @incident-deleted="reload"
        @commit-to-main-db="commitToMainDb"
        @overwrite-incident="overwriteIncident"
        @view-incident="viewIncident"
      />
    </v-card>
    <view-incident-popup
      ref="viewIncidentPopup"
      @add-flag="addFlag"
      @view-incident="viewIncident"
      @commit-to-main-db="commitToMainDb"
      @overwrite-incident="overwriteIncident"
    />
    <add-flag-popup
        ref="addFlagPopup"
        @sent="reload"
    />
    <edit-monitor-popup
      ref="editMonitorPopup"
      :has-delete-btn="false"
    />
    <confirmation ref="commitIncidentConfirmationPopup">
      <v-card-text
          v-if="type === searchTypes.UNFINISHED"
          class="pb-0"
      >
        <v-checkbox
            v-model="deleteOnDevice"
            hide-details
            label="Delete on device?"
        ></v-checkbox>
      </v-card-text>
    </confirmation>
  </div>
</template>

<script>
import _ from 'lodash';
import smoothscroll from 'smoothscroll-polyfill';
import { mapActions, mapMutations } from 'vuex';
import {
  LOAD_TABLE_FIELDS, LOAD_COLUMN_PRESETS, LOAD_SEARCH_FIELDS, LOAD_SEARCH_PRESETS,
} from '@/store/Incidents/actions';
import { SHOW_SNACKBAR } from '@/store/actions';
import {
  SET_BASIC_SEARCH_FILTERS, CLEAR_STATE, SET_SESSION_ID,
  SET_MONITOR, SET_SHOW_ADVANCED, SET_ADVANCED_SEARCH_FILTERS,
  SET_PAGINATION,
} from '@/store/Incidents/mutations';
import { SET_TITLE } from '@/store/mutations';
import SearchResultTab from '@/components/Incidents/SearchResultTab';
import Search from '@/components/Incidents/Search';
import ViewIncidentPopup from '@/components/Incidents/ViewIncidentPopup';
import AddFlagPopup from '@/components/FlaggedUsers/AddFlagPopup';
import incidentFlagMixins from '@/mixins/incidentFlagMixins';
import SEARCH_TYPES from '@/enums/searchTypes';
import EditMonitorPopup from '@/components/Monitors/EditMonitorPopup';
import INCIDENT_FIELDS from '@/enums/incidentFields';
import incidents from '@/api/incidents';
import Confirmation from '@/components/Confirmation';

export default {
  mixins: [incidentFlagMixins],
  components: {
    Confirmation,
    EditMonitorPopup,
    AddFlagPopup,
    ViewIncidentPopup,
    Search,
    SearchResultTab,
  },

  props: {
    type: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      searching: false,
      searchTypes: SEARCH_TYPES,
      target: 0,
      deleteOnDevice: false,
      options: {
        duration: 1000,
        offset: 0,
        easing: 'easeInOutCubic',
      },
    };
  },

  computed: {
    /**
     * Get incidents full state.
     *
     * @return {Object}
     */
    entity() {
      return this.$store.state.incidents;
    },
  },
  created() {
    const sessionId = window.history.state ? window.history.state.key : 1;
    const monitor = _.cloneDeep(this.entity.monitorTmp);

    if (!this.entity.sessionId || this.entity.sessionId !== sessionId) {
      this.clearState();
      this.setSessionId(sessionId);
    }
    if (monitor) {
      this.setMonitor(monitor);
    }
  },
  async mounted() {
    smoothscroll.polyfill();
    this.$store.commit(SET_TITLE, this.$t(`incidents.header.${this.type}`));
    await this.fetchData();
    this.applyFilters();
  },

  methods: {
    ...mapMutations({
      setBasicSearchFilters: `incidents/${SET_BASIC_SEARCH_FILTERS}`,
      clearState: `incidents/${CLEAR_STATE}`,
      setSessionId: `incidents/${SET_SESSION_ID}`,
      setMonitor: `incidents/${SET_MONITOR}`,
      setShowAdvanced: `incidents/${SET_SHOW_ADVANCED}`,
      setAdvancedSearchFilters: `incidents/${SET_ADVANCED_SEARCH_FILTERS}`,
      setPagination: `incidents/${SET_PAGINATION}`,
    }),
    ...mapActions({
      showSnackbar: SHOW_SNACKBAR,
      loadTableFields: `incidents/${LOAD_TABLE_FIELDS}`,
      loadColumnPresets: `incidents/${LOAD_COLUMN_PRESETS}`,
      loadSearchFields: `incidents/${LOAD_SEARCH_FIELDS}`,
      loadSearchPresets: `incidents/${LOAD_SEARCH_PRESETS}`,
    }),

    /**
     * PreApply search filters by different conditions.
     */
    applyFilters() {
      if (this.entity.monitor) {
        this.setShowAdvanced(true);
        this.$refs.search.setConditions(this.entity.monitor.conditions);
      }
      if (this.$route.query.sequenceNumber) {
        this.$refs.search.setBasicFilters({
          sequenceNumbers: this.$route.query.sequenceNumber,
        }, true);
      }
      if (this.$route.query.filters && this.$route.query.filters === 'insights') {
        this.setInsightFilters();
      }
    },

    setInsightFilters() {
      const {
        category, focus, insight, dateFrom, dateTo,
      } = this.$route.query;
      const groups = [];
      if (dateFrom && dateTo) {
        this.$refs.search.setBasicFilters({
          dateRange: [
            dateFrom,
            dateTo,
          ],
        }, false);
      }
      groups.push({
        conditions: [
          {
            field: `insights_${category}`,
            focus,
            insight: insight || null,
            type: `insights_${category}`,
          },
        ],
        operator: 'and',
      });
      this.setShowAdvanced(true);
      this.$refs.search.setConditions(groups);
    },

    /**
     * Opens a popup for save existed monitor.
     *
     * @param {Array} filters - List of advanced search filters
     */
    saveMonitor(filters) {
      if (!this.entity.monitor) {
        return;
      }
      const { monitor } = this.entity;
      monitor.conditions = filters;
      this.$refs.editMonitorPopup.edit(monitor);
    },

    /**
     * Opens a popup for add new monitor.
     *
     * @param {Array} filters - List of advanced search filters
     */
    createMonitor(filters) {
      this.$refs.editMonitorPopup.create(filters);
    },

    /**
     * Open view incident dialog.
     *
     * @param {Object} incident - Incident object
     */
    viewIncident(incident) {
      const isNonRegular = this.type !== SEARCH_TYPES.REGULAR;
      let previousIncident = null;
      let nextIncident = null;
      this.entity.items.forEach((item, index) => {
        if (item.id === incident.id) {
          if (index > 0) {
            previousIncident = this.entity.items[index - 1];
          }
          if (index < this.entity.items.length - 1) {
            nextIncident = this.entity.items[index + 1];
          }
        }
      });
      const options = {
        showViewIncidentLink: !isNonRegular,
        showActions: !isNonRegular,
        showNonRegularActions: isNonRegular,
      };
      this.$refs.viewIncidentPopup.show(
        incident,
        options,
        null,
        previousIncident,
        nextIncident,
      );
    },

    /**
     * Fetch data for current view.
     */
    async fetchData() {
      this.loading = true;
      try {
        const promises = [];
        if (this.$store.state.incidents.tableFields === null) {
          promises.push(this.loadTableFields());
        }
        if (this.$store.state.incidents.columnPresets === null) {
          promises.push(this.loadColumnPresets());
        }
        if (this.$store.state.incidents.searchFields === null) {
          promises.push(this.loadSearchFields());
        }
        if (this.$store.state.incidents.searchPresets === null) {
          promises.push(this.loadSearchPresets());
        }
        await Promise.all(promises);
      } finally {
        this.loading = false;
      }
    },

    /**
     * Is search results out of sight.
     *
     * @return {Boolean}
     */
    resultsOutOfSight() {
      const rect = this.$refs.incidents.$el.getBoundingClientRect();
      const resultsPos = rect.y;
      const indent = 110;
      const windowHeight = window.innerHeight || document.documentElement.clientHeight
          || document.body.clientHeight;
      return resultsPos + indent > windowHeight;
    },

    flushPaginationPage() {
      const pagination = _.cloneDeep(this.entity.pagination);
      pagination.page = 1;
      this.setPagination(pagination);
    },

    /**
     * Reload incidents initiated by clicking Search button.
     */
    async searchAdvanced() {
      try {
        this.searching = true;
        this.flushPaginationPage();
        await this.reload();
        if (this.resultsOutOfSight()) {
          if (this.$store.state.incidents.totalItems) {
            this.$refs.incidents.$el.scrollIntoView({ behavior: 'smooth', block: 'start' });
          } else {
            this.showSnackbar({
              title: 'No search results matched by search params.',
              color: 'blue',
            });
          }
        }
      } finally {
        this.searching = false;
      }
    },

    /**
     * Reload incidents initiated automatically by changing basic filters.
     */
    async searchBasic() {
      this.flushPaginationPage();
      await this.reload();
      if (this.resultsOutOfSight()) {
        if (this.$store.state.incidents.totalItems) {
          this.showSnackbar(`${this.$store.state.incidents.totalItems} results have been found`);
        } else {
          this.showSnackbar({
            title: 'No search results matched by search params.',
            color: 'blue',
          });
        }
      }
    },

    /**
     * Reload incidents table data.
     */
    async reload() {
      await this.$refs.incidents.reloadItems();
    },

    /**
     * Commit incident to main db.
     *
     * @param {Object} incident
     */
    commitToMainDb(incident) {
      this.deleteOnDevice = false;
      this.$refs.commitIncidentConfirmationPopup.showConfirm(
        'Confirmation',
        `Do you really want to commit incident "${incident[INCIDENT_FIELDS.SEQUENCE_NUMBER]}"?`,
        async () => {
          await incidents.commitIncidentToMainDb(incident.id, this.deleteOnDevice);
          this.reload();
        },
      );
    },

    /**
     * Overwrite regular incident.
     *
     * @param {Object} incident
     */
    overwriteIncident(incident) {
      this.deleteOnDevice = false;
      this.$refs.commitIncidentConfirmationPopup.showConfirm(
        'Overwrite existing?',
        'If you choose to continue with the overwrite, all previous data from the existing '
          + 'report will be lost and can not be undone. Do you wish to continue?',
        async () => {
          await incidents.commitIncidentToMainDb(incident.id, this.deleteOnDevice);
          this.reload();
        },
      );
    },
  },
};
</script>
