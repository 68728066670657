<template>
  <div>
    <v-data-table
      :headers="header"
      :items="items"
      :options.sync="pagination"
      :server-items-length="totalItems"
      :loading="loading"
      :height="tableHeight"
      :footer-props="{
        itemsPerPageOptions: [10,25,50]
      }"
      no-data-text="No data available"
      no-results-text="No data available"
      fixed-header
      class="header-nowrap"
    >
      <template v-slot:item.dataType="{ item }">
        <span v-if="item.dataType === reconciliationTabs.ALS">ALS Based On "R" Unit</span>
        <span v-if="item.dataType === reconciliationTabs.ALS_BASED_ON_S">
          ALS Based On "S" Unit
        </span>
        <span v-if="item.dataType === reconciliationTabs.BLS">BLS</span>
        <span v-if="item.dataType === reconciliationTabs.FIRST_RESPONDER">First Responder</span>
      </template>
      <template v-slot:item.IncidentDateTime="{ item }">
        {{ item.IncidentDate }} {{ item.IncidentTime }}
      </template>

      <template v-slot:item.IncidentAddress="{ item }">
        {{ item.IncidentStreetNumber }} {{ item.IncidentStreetName }}
      </template>

      <template v-slot:item.IncidentType="{ item }">
        {{ item.TriageInfo || item.IncidentType }}
      </template>
      <template v-slot:item.II_SequenceNumber="{ item }">
        <a
          v-if="item.II_SequenceNumber"
          @click="viewIncident(item)"
          >
          {{ item.II_SequenceNumber }}
        </a>
        <span v-else>{{ item.II_SequenceNumber }}</span>
      </template>
      <template v-slot:item.TI_Via="{ item }">
        {{ viaValuesMap[item.TI_Via] }}
      </template>
    </v-data-table>
    <view-incident-popup
        ref="viewIncidentPopup"
        :show-flag-btn="false"
        :show-nav-btn="false"
        @view-incident="viewIncident"
    />
  </div>

</template>

<script>
import _ from 'lodash';
import viaValuesMap from '@/data/Lookups/via';
import fixedHeader from '@/mixins/fixedHeader';
import reconciliation from '@/api/reconciliation';
import ViewIncidentPopup from '@/components/Incidents/ViewIncidentPopup';
import RECONCILIATION_TABS from '@/enums/reconciliationTabs';

export default {
  name: 'ReconciliationTable',
  components: { ViewIncidentPopup },
  mixins: [fixedHeader],
  props: {
    type: {
      type: String,
      required: true,
    },
    year: {
      type: Number,
      required: true,
    },
    month: {
      type: Number,
      required: true,
    },
    clientId: {
      type: Number,
      default: null,
    },
    showResponseTypeColumns: {
      type: Boolean,
      default: false,
    },
    showDataTypeColumn: {
      type: Boolean,
      default: false,
    },
    showCaseNumberColumn: {
      type: Boolean,
      default: false,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      viaValuesMap,
      loading: true,
      items: [],
      totalItems: 0,
      needUpdate: false,
      apiMap: {
        als: 'als',
        alsBasedOnS: 'als-based-on-s',
        bls: 'bls',
        firstResponder: 'first-responder',
        allInclusive: 'all-inclusive',
      },
      pagination: {
        page: 1,
        itemsPerPage: 25,
        sortBy: ['IncidentNumber'],
        sortDesc: [false],
        groupBy: [],
        groupDesc: [],
        mustSort: false,
        multiSort: false,
        totalItems: 0,
      },
      reconciliationTabs: RECONCILIATION_TABS,
    };
  },
  computed: {
    header() {
      let header = [];
      if (this.showDataTypeColumn) {
        header = header.concat([
          {
            text: 'Data Type',
            value: 'dataType',
          },
        ]);
      }
      header = header.concat([
        {
          text: 'CAD_IncidentNo',
          value: 'IncidentNumber',
        },
      ]);

      if (this.showCaseNumberColumn) {
        header = header.concat([
          {
            text: 'CAD_CaseNumber',
            value: 'CaseNumber',
          },
        ]);
      }

      header = header.concat([
        {
          text: 'CAD_DateTime',
          value: 'IncidentDateTime',
        },
        {
          text: 'CAD_Address',
          value: 'IncidentAddress',
        },
        {
          text: 'CAD_IncidentType',
          value: 'IncidentType',
        },
        {
          text: 'CAD_TransportingUnit',
          value: 'Unit',
        },
      ]);

      if (this.showResponseTypeColumns) {
        header = header.concat([
          {
            text: 'CAD_ResponseTypeStart',
            value: 'ResponseTypeStart',
          },
          {
            text: 'CAD_ResponseTypeFinal',
            value: 'ResponseTypeFinal',
          },
        ]);
      }

      return header.concat([
        {
          text: 'MC_SequenceNumber',
          value: 'II_SequenceNumber',
        },
        {
          text: 'MC_IncidentDate',
          value: 'II_IncidentDate',
        },
        {
          text: 'MC_IncidentNumber',
          value: 'II_IncidentNumber',
        },
        {
          text: 'MC_ProviderImpression_1',
          value: 'PA_ProviderImpression1',
        },
        {
          text: 'MC_ProviderImpression_2',
          value: 'PA_ProviderImpression2',
        },
        {
          text: 'MC_Mode',
          value: 'TI_Via',
        },
        {
          text: 'MC_DateSentToBilling',
          value: 'DateSentToBilling',
        },
      ]);
    },
  },
  watch: {
    /**
     * Watch pagination options and update pagination props.
     */
    pagination(newData, oldData) {
      if (!_.isEqual(newData, oldData)) {
        this.fetchData();
      }
    },

    year() {
      this.fetchData();
    },
    month() {
      this.fetchData();
    },
    isActive(newValue) {
      if (newValue && this.needUpdate) {
        this.needUpdate = false;
        this.fetchData();
      }
    },
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      if (!this.isActive) {
        this.needUpdate = true;
        return;
      }
      const type = this.apiMap[this.type];
      try {
        this.loading = true;
        const params = {
          clientId: this.clientId,
          page: this.pagination.page,
          per_page: this.pagination.itemsPerPage,
          order_by: this.pagination.sortBy[0] || 'IncidentNumber',
          sort_order: this.pagination.sortDesc[0] ? 'desc' : 'asc',
        };
        const result = await reconciliation.fetchData(type, {
          year: this.year,
          month: this.month,
          ...params,
        });
        this.items = result.results;
        this.totalItems = result.pagination.total;
      } finally {
        this.loading = false;
      }
    },

    viewIncident(incident) {
      const options = {
        showViewIncidentLink: true,
        showActions: true,
        showNonRegularActions: false,
      };
      this.$refs.viewIncidentPopup.show(
        { id: incident.id, II_SequenceNumber: incident.II_SequenceNumber },
        options,
      );
    },
  },
};
</script>
