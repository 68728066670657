<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="items"
      :loading="loading"
      :options.sync="pagination"
      :footer-props="{
        itemsPerPageOptions: [10,25,50]
      }"
      :height="tableHeight"
      fixed-header
      class="header-nowrap"
    >
      <template v-slot:body="{ items }">
        <tbody>
          <NoDataAvailable
            :headers="headers"
            :items="items"
          ></NoDataAvailable>
          <v-hover
            v-for="item in items"
            :key="item.registryId"
          >
            <tr
              slot-scope="{ hover }"
              :class="[$style.pointer, (item.registry.deleted ? $style.deleted : null)]"
              @click="clickByRow(item, $event)"
            >
              <td><a
                v-if="!item.registry.deleted"
                @click="impersonate(item.registryId)"
              >{{ item.registryId }}</a>
                <span v-if="item.registry.deleted">{{ item.registryId }}</span>
              </td>
              <td><a
                v-if="!item.registry.deleted"
                @click="impersonate(item.registryId)"
              >{{ item.name }}</a>
                <span v-if="item.registry.deleted">{{ item.name }}</span>
              </td>
              <td>{{ item.type === clientSiteTypes.CE_ENTRY ? 'CE Client' : 'Default' }}</td>
              <td>
                <a
                  v-if="!item.registry.deleted"
                  @click="impersonate(item.registryId)"
                >{{ item.domain }}.{{ baseDomain }}</a>
                <span v-if="item.registry.deleted">{{ item.domain }}.{{ baseDomain }}</span>
              </td>
              <td>{{ item.mapTypeId || '—'}}</td>
              <td>{{ item.template ? item.template.name : '—' }}</td>
              <td>{{ item.countDevices }}</td>
              <td>{{ item.lastUsed | date }}</td>
              <td>
                <v-tooltip
                  top
                  max-width="400"
                >
                  <template v-slot:activator="{ on }">
                    <div
                      :class="[$style.comment, 'no-wrap']"
                      v-on="on"
                    >{{ item.comments }}</div>
                  </template>
                  <span>{{ item.comments }}</span>
                </v-tooltip>
              </td>
              <td :class="[$style.actions, 'text-right', 'no-wrap']">
                <v-icon
                  v-if="!item.registry.deleted"
                  v-show="hover"
                  color="grey darken-2"
                  class="mr-3"
                  @click="$emit('clickEdit', item)"
                >
                  edit
                </v-icon>
                <v-icon
                  v-if="!item.registry.deleted"
                  v-show="hover"
                  class="mr-3"
                  color="grey darken-2"
                  @click="$emit('clickDelete', item)"
                >
                  delete
                </v-icon>
                <v-icon
                  v-else
                  v-show="hover"
                  class="mr-3"
                  color="grey darken-2"
                  @click="$emit('clickRestore', item)"
                >
                  restore
                </v-icon>
              </td>
            </tr>
          </v-hover>
        </tbody>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import impersonateMixins from '@/mixins/impersonateMixins';
import fixedHeader from '@/mixins/fixedHeader';
import CLIENT_SITE_TYPES from '@/enums/clientSiteTypes';
import NoDataAvailable from '../NoDataAvailable';

export default {
  name: 'ClientsTable',

  components: {
    NoDataAvailable,
  },

  mixins: [impersonateMixins, fixedHeader],

  props: {
    items: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      clientSiteTypes: CLIENT_SITE_TYPES,
      baseDomain: process.env.VUE_APP_BASE_DOMAIN,
      pagination: {
        sortDesc: [false],
        page: 1,
        sortBy: ['name'],
        itemsPerPage: 50,
      },
      headers: [
        {
          text: this.$t('clients.tableHeaders.id'),
          value: 'registryId',
        },
        {
          text: this.$t('clients.tableHeaders.name'),
          value: 'name',
          width: 200,
        },
        {
          text: this.$t('clients.tableHeaders.type'),
          value: 'type',
          width: 110,
        },
        {
          text: this.$t('clients.tableHeaders.domain'),
          value: 'domain',
        },
        {
          text: this.$t('clients.tableHeaders.mapToType'),
          value: 'mapTypeId',
        },
        {
          text: this.$t('clients.tableHeaders.template'),
          value: 'template.name',
          sort: (a, b) => this.prepareTemplate(a) - this.prepareTemplate(b),
        },
        {
          text: this.$t('clients.tableHeaders.countDevices'),
          value: 'countDevices',
        },
        {
          text: this.$t('clients.tableHeaders.lastUsed'),
          value: 'lastUsed',
        },
        {
          text: this.$t('clients.tableHeaders.comment'),
          sortable: false,
          width: 200,
        },
        {
          text: '',
          sortable: false,
        },
      ],
    };
  },
  methods: {
    /**
     * Get count dots in template version.
     *
     * @param {String} version - Template version
     *
     * @returns {number}
     */
    getCountOfDots(version) {
      let count = 0;
      let position = 0;
      const condition = true;

      while (condition) {
        if (typeof version !== 'string') {
          break;
        }

        const foundPosition = version.indexOf('.', position);

        if (foundPosition === -1) {
          break;
        }

        count++;
        position = foundPosition + 1;
      }

      return count;
    },

    /**
     * Prepare template version.
     *
     * @param {String} version Template version
     *
     * @returns {number}
     */
    prepareTemplate(version) {
      const dotCount = this.getCountOfDots(version);
      let preparedVersionStr = version;

      if (dotCount === 0) {
        preparedVersionStr += '.0.0.0';
      } else if (dotCount === 1) {
        preparedVersionStr += '.0.0';
      } else if (dotCount === 2) {
        preparedVersionStr += '.0';
      }

      const preparedVersionArr = preparedVersionStr.split('.');

      return Number(preparedVersionArr[0]) * (256 ** 3)
          + Number(preparedVersionArr[1]) * (256 ** 2)
          + Number(preparedVersionArr[2]) * (256 ** 1)
          + Number(preparedVersionArr[3]) * (256 ** 0);
    },

    clickByRow(client, event) {
      const selection = window.getSelection();
      if (
        selection.type !== 'Range'
        && !client.registry.deleted
        && event.target.tagName !== 'A'
        && event.target.tagName !== 'I'
      ) {
        switch (event.target.textContent) {
          case 'delete':
            this.$emit('clickDelete', client);
            break;
          default:
            this.$emit('clickEdit', client);
        }
      }
    },
  },
};
</script>

<style lang="scss" module>
.nowrap {
  white-space: nowrap;
}

.deleted {
  background-color: $red-light;
}

.actions {
  width: 20%;
}

.comment {
  cursor: pointer;
  max-width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.pointer {
  cursor: pointer;
}
</style>
