<template>
  <v-data-table
    :headers="headers"
    :items="items"
    :options.sync="paginationOptions"
    :server-items-length="totalItems"
    :loading="loading"
    :footer-props="{
      itemsPerPageOptions: [10,25,50]
    }"
    :height="tableHeight"
    fixed-header
  >
    <template v-slot:body="{ items }">
      <tbody>
        <NoDataAvailable
          :headers="headers"
          :items="items"
        />
        <v-hover
          v-for="(item, index) in items"
          :key="index"
        >
          <tr
            slot-scope="{ hover }"
            :class="item.registry.deleted ? $style.deleted : null"
          >
            <td>{{ item.firstName }}</td>
            <td>{{ item.lastname }}</td>
            <td>{{ item.userTypeId | activeGroup }}</td>
            <td>
              <span
                  v-if="item.phoneVerified"
                  :class="[$style.verified]"
              >{{ item.phone }}</span>
              <v-tooltip
                  v-else
                  bottom
                  content-class="primary tooltip-bottom"
              >
                <template v-slot:activator="{ on }">
                  <span
                      :class="[$style.not_verified]"
                      v-on="on"
                  >{{ item.phone }}</span>
                </template>
                <div class="text-center">
                  The phone is not verified
                </div>
              </v-tooltip>
            </td>
            <td>
              <span
                v-if="item.emailVerified"
                :class="[$style.verified]"
              >{{ item.email }}</span>
              <v-tooltip
                  v-else
                  bottom
                  content-class="primary tooltip-bottom"
              >
                <template v-slot:activator="{ on }">
                  <span
                      :class="[$style.not_verified]"
                      v-on="on"
                  >{{ item.email }}</span>
                </template>
                <div class="text-center">
                  The email is not verified
                </div>
              </v-tooltip>
            </td>
            <td>{{ item.lastLogin | datetime }}</td>
            <td v-if="siteRole === 'client'">{{ item.EMT }}</td>
            <td :class="[$style.actions, 'text-right', 'no-wrap']">
              <div v-if="showActions(item)">
                <v-icon
                  v-if="!item.registry.deleted"
                  v-show="hover"
                  color="grey darken-2"
                  class="mr-3"
                  @click="$emit('clickEdit', item)"
                >
                  edit
                </v-icon>
                <v-icon
                  v-if="!item.registry.deleted"
                  v-show="hover"
                  class="mr-3"
                  color="grey darken-2"
                  @click="$emit('clickDelete', item)"
                >
                  delete
                </v-icon>
                <v-icon
                  v-if="item.isDeactivated && siteRole === 'client'"
                  v-show="hover"
                  class="mr-3"
                  color="grey darken-2"
                  @click="$emit('clickRestore', item)"
                >
                  restore
                </v-icon>
              </div>
            </td>
          </tr>
        </v-hover>
      </tbody>
    </template>
  </v-data-table>
</template>

<script>
import fixedHeader from '@/mixins/fixedHeader';
import USER_ROLE_NAMES from '@/enums/userRoleNames';
import USER_ROLES from '@/enums/userRoles';
import NoDataAvailable from '../NoDataAvailable';

export default {
  name: 'UsersTable',

  filters: {
    activeGroup(userTypeId) {
      const map = {
        ca: USER_ROLE_NAMES.CLIENT_ADMIN,
        ccu: USER_ROLE_NAMES.CLIENT_CAPTAIN_USER,
        cfu: USER_ROLE_NAMES.CLIENT_FIREFIGHTER_USER,
        cqa: USER_ROLE_NAMES.CLIENT_QA_USER,
        ceu: USER_ROLE_NAMES.CLIENT_CE_USER,
        cap: USER_ROLE_NAMES.CLIENT_AP_USER,
        hu: USER_ROLE_NAMES.HOSPITAL_USER,
        ha: USER_ROLE_NAMES.HOSPITAL_ADMIN,
      };
      return map[userTypeId];
    },
  },
  components: {
    NoDataAvailable,
  },
  mixins: [fixedHeader],

  props: {
    siteRole: {
      type: String,
      required: true,
    },
    totalItems: {
      type: Number,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    pagination: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    showActionsOnAdmins: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      paginationOptions: this.pagination,
    };
  },
  computed: {
    headers() {
      const headers = [
        {
          text: this.$t('users.tableHeaders.firstName'),
          value: 'firstName',
        },
        {
          text: this.$t('users.tableHeaders.lastName'),
          value: 'lastName',
        },
        {
          text: this.$t('users.tableHeaders.group'),
          value: 'userTypeId',
        },
        {
          text: this.$t('users.tableHeaders.phone'),
          value: 'phone',
        },
        {
          text: this.$t('users.tableHeaders.email'),
          value: 'email',
        },
        {
          text: this.$t('users.tableHeaders.lastlogin'),
          value: 'lastLogin',
        },
      ];
      if (this.siteRole === 'client') {
        headers.push({
          text: this.$t('users.tableHeaders.license'),
          value: 'emt',
        });
      }
      headers.push({
        sortable: false,
      });
      return headers;
    },
  },
  watch: {
    /**
     * Watch pagination options and update pagination
     */
    paginationOptions() {
      this.$emit('update:pagination', this.paginationOptions);
    },
  },
  methods: {
    /**
     * Can see user actions.
     *
     * @param {Object} user - User object.
     */
    showActions(user) {
      return (
        user.userTypeId !== USER_ROLES.CLIENT_ADMIN || this.showActionsOnAdmins
      );
    },
  },
};
</script>

<style>
@import '../../styles/tooltip.css';
</style>

<style lang="scss" module>
.nowrap {
  white-space: nowrap;
}

.deleted {
  background-color: $red-light;
}

.actions {
  width: 20%;
}

.not_verified {
  color: red;
}
.verified {
  color: green;
}
</style>
