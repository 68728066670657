<template>
  <div>
    <v-dialog
        v-model="dialog"
        max-width="800px"
        persistent
    >
      <v-card v-if="loading">
        <v-card-text class="mt-0 pt-3">
          Loading...
          <v-progress-linear
              indeterminate
              color="primary"
              class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
      <v-card v-else>
        <v-card-title>{{ sequenceNumber }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: calc(100vh - 300px); max-height: 550px; overflow-y: scroll">
          <v-layout wrap>
            <v-flex xs12>
              <v-text-field
                  v-model="assessmentAtFormatted"
                  label="Assessment At"
                  prepend-inner-icon="event"
                  clearable
                  name="scheduledDate"
                  readonly
                  @click="openDatePicker"
              />
              <datetime
                  ref="dateTime"
                  v-model="assessmentAtDateTime"
                  type="datetime"
                  input-class="d-none"
                  class="theme-red"
                  format="MM-DD-YYYY"
                  zone="UTC"
                  use12-hour
              />
            </v-flex>
            <v-flex xs5>
              <v-text-field
                v-model="apData.apCompanyName"
                label="AP Company"
                maxlength="255"
              />
            </v-flex>
            <v-flex
                xs6
                offset-xs1
            >
              <v-text-field
                  v-model="apData.apLicenseNumber"
                  label="AP License"
                  maxlength="255"
              />
            </v-flex>
            <v-flex xs5>
              <v-text-field
                  v-model="apData.apLastName"
                  label="AP Last Name"
                  maxlength="255"
              />
            </v-flex>
            <v-flex
                xs6
                offset-xs1
            >
              <v-text-field
                  v-model="apData.apFirstName"
                  label="AP First Name"
                  maxlength="255"
              />
            </v-flex>
            <v-flex xs5>
            </v-flex>
            <v-flex
                xs6
                offset-xs1
            >
            </v-flex>
            <v-flex xs12>
              <v-textarea
                  v-model="apData.apComments"
                  label="Comments"
                  outlined
                  rows="10"
                  maxlength="5050"
              />
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer />
          <v-btn
              text
              color="blue darken-1"
              @click="dialog = false"
          >
            Cancel
          </v-btn>
          <v-btn
              text
              color="primary"
              :loading="saving"
              @click="saveApForm"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import moment from 'moment-timezone';
import incidents from '@/api/incidents';
import errorAlertMixins from '@/mixins/errorAlertMixins';
import { Datetime } from 'vue-datetime';

export default {
  name: 'EditApFormPopup',
  mixins: [errorAlertMixins],
  components: {
    Datetime,
  },
  data() {
    return {
      dialog: false,
      loading: false,
      saving: false,
      incidentId: null,
      sequenceNumber: null,
      apData: {
        apAssessmentAt: null,
        apCompanyName: null,
        apLicenseNumber: null,
        apLastName: null,
        apFirstName: null,
        apComments: null,
      },
    };
  },
  computed: {
    /**
     * Computed property for schedule date input model.
     */
    assessmentAtFormatted: {
      get() {
        return this.apData.apAssessmentAt
          ? moment.parseZone(this.apData.apAssessmentAt).format('MM/DD/YYYY h:mm A')
          : null;
      },
      set(value) {
        this.apData.apAssessmentAt = value;
      },
    },
    assessmentAtDateTime: {
      get() {
        return this.apData.apAssessmentAt
          ? moment.parseZone(this.apData.apAssessmentAt, 'YYYY-MM-DD HH:mm:ss').toISOString()
          : null;
      },
      set(value) {
        this.apData.apAssessmentAt = value
          ? moment.parseZone(value).format('YYYY-MM-DD HH:mm:ss')
          : null;
      },
    },
  },
  methods: {
    /**
     * Show edit AP form.
     */
    async show(incidentId, sequenceNumber) {
      this.incidentId = incidentId;
      this.sequenceNumber = sequenceNumber;
      try {
        this.loading = true;
        this.apData = await incidents.getApForm(incidentId);
      } finally {
        this.loading = false;
      }
      this.dialog = true;
    },

    /**
     * Close the edit form.
     */
    hide() {
      this.dialog = false;
    },

    /**
     * Handler of click by schedule date input.
     */
    openDatePicker(event) {
      this.$refs.dateTime.open(event);
    },

    /**
     * Save AP Form.
     */
    async saveApForm() {
      try {
        this.saving = true;
        await incidents.saveApForm(this.incidentId, this.apData);
        this.hide();
        this.$emit('saved');
      } finally {
        this.saving = false;
      }
    },
  },
};
</script>
