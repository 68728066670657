<template>
  <div class="nemsis-settings-table">
    <v-data-table
        :headers="headers"
        :items="items"
        :options.sync="pagination"
        :loading="loading"
        :height="tableHeight"
        :footer-props="{
          itemsPerPageOptions: [10,25,50]
        }"
        no-data-text="No data available"
        no-results-text="No data available"
        fixed-header
        class="header-nowrap"
    >
      <template v-slot:item.interval="{ item }">
        {{ item.interval ? intervals[item.interval] : 'Disabled' }}
      </template>
      <template v-slot:item.actions="{ item }">
        <div class="table-actions">
          <v-btn
            class="mr-3 float-right"
            icon
            @click="$emit('deleteSetting', item)"
          >
            <v-icon color="grey darken-2">delete</v-icon>
          </v-btn>
          <v-btn
              class="float-right"
              icon
              @click="$emit('editSetting', item)"
          >
            <v-icon color="grey darken-2">edit</v-icon>
          </v-btn>
        </div>
      </template>
    </v-data-table>
  </div>

</template>

<script>
import fixedHeader from '@/mixins/fixedHeader';

export default {
  name: 'SettingsTable',
  mixins: [fixedHeader],
  props: {
    items: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      pagination: {
        page: 1,
        itemsPerPage: 25,
        sortBy: ['clientId'],
        sortDesc: [false],
        groupBy: [],
        groupDesc: [],
        mustSort: false,
        multiSort: false,
        totalItems: 0,
      },
      headers: [
        {
          text: 'Client',
          value: 'client.name',
        },
        {
          text: 'Username',
          value: 'username',
        },
        {
          text: 'Organization',
          value: 'organization',
        },
        {
          text: 'Scheduled Export',
          value: 'interval',
        },
        {
          text: '',
          value: 'actions',
          sortable: false,
        },
      ],
      intervals: {
        daily: 'Every Day',
        weekly: 'Every Monday',
        monthly: 'First of Every Month',
      },
    };
  },
};
</script>
<style lang="scss">
.nemsis-settings-table {
  .table-actions {
    visibility: hidden;
  }
  tr:hover .table-actions {
    visibility: visible;
  }
}
</style>
