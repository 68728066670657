<template>
  <div>
    <v-dialog
        v-model="dialog"
        :max-width="zoom || needCompare ? '1900px' : '800px'"
        scrollable
        :transition="false"
    >
      <v-card v-if="loading">
        <v-card-text class="mt-3">
          Loading...
          <v-progress-linear
              indeterminate
              color="primary"
              class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
      <v-card
          v-else
          :loading="loadingAlreadyOpened"
      >
        <v-toolbar
          v-if="incident"
          flat
        >
          <span class="popup-title mr-5">
            {{ incident[incidentFields.SEQUENCE_NUMBER] }}
          </span>
          <div v-if="showNavBtn">
            <v-btn
                small
                icon
                :disabled="previousIncident === null"
                @click="$emit('view-incident', previousIncident)"
            >
              <v-icon>mdi-chevron-up</v-icon>
            </v-btn>
            <v-btn
                small
                icon
                class="mr-3"
                :disabled="nextIncident === null"
                @click="$emit('view-incident', nextIncident)"
            >
              <v-icon>mdi-chevron-down</v-icon>
            </v-btn>
          </div>
          <div v-if="showActions">
            <v-btn
                v-if="showViewIncidentLink && $can('view', 'incident')"
                text
                color="blue"
                small
                class="mr-3"
                :to="{ name: routes.INCIDENT_VIEW, params: { id: incident.id }}"
            >
              View incident
            </v-btn>
            <v-btn
                v-if="showFlagBtn && canAddFlag(incident)"
                text
                color="blue"
                small
                class="mr-3"
                @click="$emit('add-flag', incident)"
            >
              Flag for review
            </v-btn>
            <v-btn
                v-if="showNarcoticRequest && $can('printNarcoticRequest', 'incident')"
                text
                color="blue"
                small
                :loading="printLoading"
                :disabled="!imagesLoaded"
                @click="printImage(true)"
            >
              Narcotic Request
            </v-btn>
          </div>
          <v-spacer />
          <div v-if="showNonRegularActions">
            <v-btn
                v-if="incident.IsUnique"
                text
                color="blue"
                small
                @click="$emit('commit-to-main-db', incident); dialog = false;"
            >
              Commit to Main DB
            </v-btn>
            <v-btn
                v-else
                text
                color="blue"
                small
                @click="$emit('overwrite-incident', incident); dialog = false;"
            >
              Overwrite previous record
            </v-btn>
          </div>
          <v-btn
              icon
              @click="dialog = false;"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-divider></v-divider>
        <v-toolbar flat>
          <v-btn
              class="mr-3"
              :disabled="zoom === true"
              @click="setZoom(true)"
          >
            <v-icon>mdi-magnify-plus-outline</v-icon>
          </v-btn>
          <v-btn
              class="mr-3"
              :disabled="zoom === false"
              @click="setZoom(false)"
          >
            <v-icon>mdi-magnify-minus-outline</v-icon>
          </v-btn>
          <v-select
              v-model="selectedIndex"
              :items="selectItems"
              hide-details
              class="mr-3"
          />
          <v-spacer />
          <v-btn
              class="mr-3"
              :disabled="images.length === 0"
              target="blank"
              :loading="downloading || pdfWaiting"
              @click="download"
          >
            <v-icon>mdi-download</v-icon>
          </v-btn>
          <v-btn
              class="mr-3"
              :loading="printLoading"
              :disabled="!imagesLoaded"
              @click="printImage(false)"
          >
            <v-icon>mdi-printer</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text
          v-if="dialog"
          id="contentContainer"
        >
          <webp-supports-alert />
          <v-row>
            <v-col md="1">
              <v-btn
                fab
                small
                :disabled="selectedIndex === 0"
                class="arrow-btn"
                @click="selectedIndex--"
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
            </v-col>
            <v-col
              sm="10"
              class="compare-container"
              style="min-height: 800px;"
            >
              <div
                v-if="needCompare"
                id="imgBlock1"
                :class="[(!zoom ? 'half left' : 'full compare top')]"
                v-bind:style="zoom ? { height: zoomContainerHeight + 'px' } : {}"
              >
                <template v-for="(item, index) in regularIncidentImages">
                  <v-img
                      v-show="index === selectedIndex"
                      v-bind:key="'img_' + index"
                      :src="item.url"
                  >
                    <template v-slot:placeholder>
                      <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                      >
                        <v-progress-circular
                            indeterminate
                            color="darkgray"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </template>
              </div>
              <div
                id="imgBlock2"
                :class="[
                    (needCompare && !zoom ? 'half right' : 'full'),
                    (needCompare && zoom ? 'compare': '')
                ]"
                v-bind:style="needCompare && zoom ? { height: zoomContainerHeight + 'px' } : {}"
              >
                <template v-if="incidentInfo.inRendering">
                  <v-row
                      class="align-center justify-center in-rendering"
                  >
                    <v-col
                      cols="12"
                      class="text-center"
                    >
                      <v-progress-circular
                          indeterminate
                          color="darkgray"
                      />
                      <br />
                      <br />
                      In Rendering...
                    </v-col>
                  </v-row>
                </template>
                <template v-for="(item, index) in images">
                  <v-img
                      v-show="index === selectedIndex"
                      v-bind:key="'img_' + index"
                      :src="item.url"
                  >
                    <template v-slot:placeholder>
                      <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                      >
                        <v-progress-circular
                            indeterminate
                            color="darkgray"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </template>
              </div>
            </v-col>
            <v-col sm="1">
              <v-btn
                  fab
                  small
                  :disabled="selectedIndex === images.length-1"
                  class="arrow-btn"
                  @click="selectedIndex++"
              >
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <print-images
              ref="print"
              :images="printCurrentImages"
              unique-id="printCurrentPage"
              :images-loaded.sync="imagesLoaded"
              :print-loading.sync="printLoading"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import INCIDENT_FIELDS from '@/enums/incidentFields';
import incidentFlagMixins from '@/mixins/incidentFlagMixins';
import downloadPdf from '@/mixins/downloadPdf';
import incidentsApi from '@/api/incidents';
import PrintImages from '@/components/Incidents/PrintImages';
import ROUTES from '@/enums/routes';
import WebpSupportsAlert from '@/components/WebpSupportsAlert';

export default {
  name: 'ViewIncidentPopup',
  components: { WebpSupportsAlert, PrintImages },
  mixins: [incidentFlagMixins, downloadPdf],
  props: {
    showNavBtn: {
      type: Boolean,
      default: true,
    },
    showFlagBtn: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      dialog: false,
      zoom: false,
      loading: false,
      loadingAlreadyOpened: false,
      loadingImage: true,
      imagesLoaded: false,
      incident: null,
      incidentInfo: null,
      incidentFields: INCIDENT_FIELDS,
      routes: ROUTES,
      selectedIndex: 0,
      downloading: false,
      pdfWaiting: false,
      printLoading: false,
      showViewIncidentLink: true,
      showActions: true,
      showNonRegularActions: false,
      showNarcoticRequest: true,
      contentHeight: 0,
      scrollingBlock: null,
      previousIncident: null,
      nextIncident: null,
      loaded: [],
    };
  },
  updated() {
    const block1 = document.getElementById('imgBlock1');
    if (block1) {
      block1.removeEventListener('scroll', this.scrollBlock);
      block1.addEventListener('scroll', this.scrollBlock);
    }
    const block2 = document.getElementById('imgBlock2');
    if (block2) {
      block2.removeEventListener('scroll', this.scrollBlock);
      block2.addEventListener('scroll', this.scrollBlock);
    }
  },
  created() {
    window.addEventListener('resize', this.recalculateHeights);
  },
  destroyed() {
    window.removeEventListener('resize', this.recalculateHeights);
  },
  computed: {
    zoomContainerHeight() {
      return this.contentHeight / 2 - 50;
    },
    images() {
      if (!this.incidentInfo || this.incidentInfo.inRendering) {
        return [];
      }
      return this.incidentInfo.images;
    },
    regularIncidentImages() {
      if (!this.incidentInfo || this.incidentInfo.inRendering) {
        return [];
      }
      return this.incidentInfo.regularIncidentImages;
    },
    printCurrentImages() {
      const urls = [];
      if (this.images.length) {
        urls.push(this.images[this.selectedIndex].url);
      }
      return urls;
    },
    needCompare() {
      return this.incidentInfo
          && Object.prototype.hasOwnProperty.call(this.incidentInfo, 'regularIncidentImages');
    },
    selectItems() {
      const mapNames = {
        page1: 'Page 1',
        page2: 'Page 2',
        page3: 'Page 3',
        page4: 'Page 4',
        page5: 'PUCC Form',
        page6: 'AP Form',
        lead12_image1: 'Lead12 - 1',
        lead12_image2: 'Lead12 - 2',
        photo1_img: 'Photo',
        image_lead: 'Lead Image',
        image_additional1: 'Additional 1',
        image_additional2: 'Additional 2',
        image_additional3: 'Additional 3',
      };
      return this.images.map((item, index) => ({
        text: mapNames[item.type + (item.page_num || '')],
        value: index,
      }));
    },
  },
  watch: {
    selectedIndex() {
      this.imagesLoaded = false;
    },
  },
  methods: {
    /**
     * Shows view incident popup.
     *
     * @param {Object} incident - Object with data of incident
     * @param {Object} options - Show incident popup options
     * @param {Number} id - Index of selected image to show
     * @param {Object} previousIncident - Previous incident in search list result
     * @param {Object} nextIncident - Next incident in search list result
     */
    async show(
      incident,
      options = {},
      id = null,
      previousIncident = null,
      nextIncident = null,
    ) {
      const alreadyOpenedDialog = this.dialog;
      this.dialog = true;
      this.zoom = false;
      this.showViewIncidentLink = options.showViewIncidentLink ?? true;
      this.showActions = options.showActions ?? true;
      this.showNonRegularActions = options.showNonRegularActions ?? false;
      this.showNarcoticRequest = options.showNarcoticRequest ?? true;
      this.incident = incident;
      this.selectedIndex = id === null ? 0 : id;
      this.downloading = false;
      this.pdfWaiting = false;
      this.printLoading = false;
      this.imagesLoaded = false;
      this.previousIncident = previousIncident;
      this.nextIncident = nextIncident;
      this.loaded = [];

      if (alreadyOpenedDialog) {
        this.loadingAlreadyOpened = true;
      } else {
        this.loading = true;
      }
      try {
        this.incidentInfo = await incidentsApi.fetchIncident(incident.id);
        incidentsApi.viewLog(incident.id);
      } finally {
        this.loading = false;
        this.loadingAlreadyOpened = false;
      }
    },
    setZoom(value) {
      this.zoom = value;
      this.recalculateHeights();
    },
    download() {
      if (this.images[this.selectedIndex].type === 'page') {
        this.downloadPdf(this.incident.id, 'page', this.images[this.selectedIndex].page_num);
      } else {
        const win = window.open(this.images[this.selectedIndex].url, '_blank');
        win.focus();
      }
    },
    recalculateHeights() {
      const container = document.getElementById('contentContainer');
      this.contentHeight = container ? container.offsetHeight : 0;
    },
    scrollBlock(event) {
      const block = event.target.id === 'imgBlock1' ? 1 : 2;
      // fix double scroll bug
      if (this.scrollingBlock !== null && this.scrollingBlock !== block) {
        this.scrollingBlock = null;
        return;
      }
      this.scrollingBlock = block;
      const id = event.target.id === 'imgBlock1' ? 'imgBlock2' : 'imgBlock1';
      const element = document.getElementById(id);
      element.scrollTo(event.target.scrollLeft, event.target.scrollTop);
    },
    /**
     * Print image.
     *
     * @param {Boolean} narcRequest - is it narcotic request print
     */
    printImage(narcRequest = false) {
      const item = this.images[this.selectedIndex];
      const params = {
        type: item.type,
        pageNumber: item.page_num,
        isNarcoticRequest: narcRequest,
      };
      incidentsApi.printLog(this.incident.id, params);

      this.$refs.print.print(narcRequest);
    },
  },
};
</script>

<style lang="scss">
.popup-title {
  font-size: 18px;
}
.arrow-btn {
  position: absolute;
  top: 50%;
}
.in-rendering {
  position: absolute;
  top: 50%;
  left: 45%;
}
.compare-container {
  .half {
    width: 49%;
  }
  .half.left {
    float: left;
  }
  .half.right {
    float: right;
  }
  .full {
    width: 100%;
  }
  .full.compare {
    overflow: auto;
  }
  .full.compare.top {
    margin-bottom: 20px;
  }
}
</style>
