const ROUTES = {
  DASHBOARD: 'dashboard',
  USERS: 'users',
  CE_HOME: 'ceHome',
  CE_ENTRY: 'ceEntry',
  CE_ENTRY_AGENCY_OVERVIEW: 'ceEntryAgencyOverview',
  CE_ENTRY_SKILLS_OVERVIEW: 'ceEntrySkillsOverview',
  CE_ENTRY_AGENCY_OVERVIEW_USER: 'ceEntryAgencyOverviewUser',
  CE_ENTRY_ENTRIES: 'ceEntryEntries',
  CE_SETTINGS: 'ceSettings',
  USERS_DELETED: 'usersDeleted',
  CLIENTS: 'clients',
  ADMINS: 'admins',
  HOSPITALS: 'hospitals',
  TEMPLATES: 'templates',
  HOSPITAL_TYPES: 'hospitalTypes',
  MATERIALS: 'materials',
  INVOICES: 'invoices',
  HOSPITAL_INCIDENTS: 'hospitalIncidents',
  HOSPITAL_INCIDENTS_LOG: 'hospitalIncidentsLog',
  MY_HOSPITAL: 'myHospital',
  INCIDENTS_REGULAR: 'incidentsRegular',
  INCIDENTS_FLAGGED: 'incidentsFlagged',
  INCIDENTS_DELETED: 'incidentsDeleted',
  INCIDENTS_UNFINISHED: 'incidentsUnfinished',
  INCIDENTS_AP_FORMS: 'incidentsApForms',
  INCIDENTS_LOG: 'incidentsLog',
  INCIDENTS_TRANSMITTED: 'incidentsTransmitted',
  INCIDENTS_AIRDROPPED: 'incidentsAirdropped',
  INCIDENTS_MONITORS: 'incidentsMonitors',
  INSIGHTS: 'insights',
  INCIDENT_VIEW: 'incidentView',
  DEVICES: 'devices',
  PROFILE: 'profile',
  LOGIN: 'login',
  FORGOT: 'forgot',
  FORGOT_CHANGE_PASSWORD: 'forgotChangePassword',
  LONG_BEACH_BILLING: 'longBeachBilling',
  RECONCILIATION: 'reconciliation',
  NFIRS_EXPORT: 'nfirsExport',
  CAD_MONITORING: 'cadMonitoring',
  API_AUDIT: 'apiAuditLog',
  LAST_IMPORTED_INCIDENTS: 'lastImportedIncidents',
  FLAGGED_USERS: 'flaggedUsers',
  DUPLICATE_INCIDENTS: 'duplicateIncidents',
  INCIDENT_SYSTEM_SEARCH: 'incidentSystemSearch',
  PUSH_NOTIFICATIONS: 'pushNotifications',
  RELEASE_NOTES: 'releaseNotes',
  UTILITIES: 'utilities',
  UTILITIES_EXPORT: 'utilitiesExport',
  OD_MAP_EXPORTS: 'utilitiesOdMapExports',
  HDE_EXPORTS: 'hdeExports',
  ET3_EXPORT_UTILITY: 'et3ExportUtility',
  DHS_EXPORT_UTILITY: 'dhsExportUtility',
  FIRST_RUN_DEVICES: 'firstRunDevices',
  PROFILE_SETTINGS: 'profileSettings',
  ERRORS_UTILITY: 'errorsUtility',
  IMPERSONATE_AND_REDIRECT: 'impersonateAndRedirect',
  FAXES: 'faxes',
  EXPORT_LOG_BILLING: 'exportLogBilling',
  EXPORT_LOG_DHS: 'exportLogDhs',
  EXPORT_LOG_UNFILTERED: 'exportLogUnfiltered',
};

export default ROUTES;
