import ROUTES from '../../enums/routes';
import ACL from '../../enums/aclGlobalRules';

// eslint-disable-next-line no-unused-vars
export default function getNavigation(store) {
  return [
    {
      icon: 'dashboard',
      title: 'Dashboard',
      route: ROUTES.DASHBOARD,
    },
    {
      icon: 'domain',
      title: 'Clients',
      route: ROUTES.CLIENTS,
    },
    {
      icon: 'account_box',
      title: 'Admins',
      route: ROUTES.ADMINS,
    },
    {
      icon: 'local_hospital',
      title: 'Hospitals',
      route: ROUTES.HOSPITALS,
    },
    {
      icon: 'description',
      title: 'Templates',
      route: ROUTES.TEMPLATES,
    },
    {
      icon: 'people',
      title: 'Users',
      route: ROUTES.USERS,
    },
    {
      icon: 'mdi-book-education-outline',
      title: 'CE Entry/Reports',
      route: ROUTES.CE_ENTRY,
      isAvailable() {
        return store.state.user && store.state.user.ceHoursEnabled;
      },
    },
    {
      icon: 'content_paste',
      title: 'Incidents',
      route: ROUTES.HOSPITAL_INCIDENTS,
    },
    {
      icon: 'mdi-format-list-bulleted',
      title: 'Log',
      route: ROUTES.HOSPITAL_INCIDENTS_LOG,
    },
    {
      icon: 'mdi-hospital-building',
      title: 'My Hospital',
      route: ROUTES.MY_HOSPITAL,
    },
    {
      icon: 'content_paste',
      title: 'Incidents',
      route: ROUTES.INCIDENTS_REGULAR,
      isAvailable() {
        return store.getters.ability.can('showIncidentsRoot', 'navigation');
      },
    },
    {
      icon: 'notification_important',
      title: 'Monitor/Alerts',
      route: ROUTES.INCIDENTS_MONITORS,
      isAvailable() {
        return store.getters.ability.can('showMonitorsRoot', 'navigation');
      },
    },
    {
      icon: 'mdi-head-lightbulb-outline',
      title: 'QA/QI Insights',
      route: ROUTES.INSIGHTS,
      isAvailable() {
        return store.getters.ability.can('showQaInsightsRoot', 'navigation');
      },
    },
    {
      icon: 'mdi-clipboard-text-outline',
      title: 'AP ToBeCompleted',
      route: ROUTES.INCIDENTS_AP_FORMS,
      isAvailable() {
        return store.getters.ability.can('showApToBeCompletedRoot', 'navigation');
      },
    },
    {
      icon: 'content_paste',
      title: 'Incidents',
      items: [
        {
          icon: 'mdi-clipboard-outline',
          title: 'Regular',
          route: ROUTES.INCIDENTS_REGULAR,
          isAvailable() {
            return store.getters.ability.can('showIncidentsNestedMenu', 'navigation');
          },
        },
        {
          icon: 'mdi-clipboard-text-outline',
          title: 'AP ToBeCompleted',
          route: ROUTES.INCIDENTS_AP_FORMS,
          isAvailable() {
            return store.getters.ability.can('showIncidentsNestedMenu', 'navigation');
          },
        },
        {
          icon: 'mdi-flag',
          title: 'Flagged',
          route: ROUTES.INCIDENTS_FLAGGED,
        },
        {
          icon: 'mdi-file-question-outline',
          title: 'Unfinished',
          route: ROUTES.INCIDENTS_UNFINISHED,
        },
        {
          icon: 'mdi-delete-sweep-outline',
          title: 'Deleted',
          route: ROUTES.INCIDENTS_DELETED,
        },
        {
          icon: 'mdi-format-list-bulleted',
          title: 'Log',
          route: ROUTES.INCIDENTS_LOG,
        },
        {
          icon: 'tablet_mac',
          title: 'Transmitted',
          route: ROUTES.INCIDENTS_TRANSMITTED,
        },
        {
          icon: 'mdi-cast-audio-variant',
          title: 'AirDropped',
          route: ROUTES.INCIDENTS_AIRDROPPED,
        },
        {
          icon: 'notification_important',
          title: 'Monitor/Alerts',
          route: ROUTES.INCIDENTS_MONITORS,
          isAvailable() {
            return store.getters.ability.can('showIncidentsNestedMenu', 'navigation');
          },
        },
        {
          icon: 'mdi-head-lightbulb-outline',
          title: 'QA/QI Insights',
          route: ROUTES.INSIGHTS,
          isAvailable() {
            return store.getters.ability.can('showIncidentsNestedMenu', 'navigation');
          },
        },
      ],
    },
    {
      icon: 'devices',
      title: 'Devices',
      route: ROUTES.DEVICES,
    },
    {
      icon: 'picture_as_pdf',
      title: 'Reference Materials',
      route: ROUTES.MATERIALS,
    },
    {
      icon: 'settings',
      title: 'Settings',
      route: ROUTES.PROFILE_SETTINGS,
    },
    {
      icon: 'mdi-receipt-outline',
      title: 'Invoicing',
      route: ROUTES.INVOICES,
    },
    {
      icon: 'build',
      title: 'Utilities',
      route: ROUTES.UTILITIES,
      divided: true,
      items: [
        {
          title: 'Reports/Incidents',
          icon: 'content_paste',
          items: [
            {
              icon: 'compare',
              title: 'Duplicate Incidents',
              route: ROUTES.DUPLICATE_INCIDENTS,
              description:
                  'Allows to resolve issue when incidents have same IncidentNumber.',
            },
            {
              icon: 'mdi-clipboard-search-outline',
              title: 'Incident System Search',
              route: ROUTES.INCIDENT_SYSTEM_SEARCH,
              description:
                  'Search incidents by entire system',
            },
          ],
        },
        {
          title: 'DHS/Billing',
          icon: 'mdi-bank-transfer',
          items: [
            {
              icon: 'folder_shared',
              title: 'Reconciliation',
              route: ROUTES.RECONCILIATION,
              description: 'Reconciliation.',
            },
            {
              icon: 'folder_shared',
              title: 'Long Beach Billing',
              route: ROUTES.LONG_BEACH_BILLING,
              description: 'Billing data of Long Beach Client.',
            },
            {
              icon: 'import_export',
              title: 'DHS / Billing Export',
              route: ROUTES.UTILITIES_EXPORT,
              description: 'Manual management of DHS or Billing export.',
            },
            {
              icon: 'import_export',
              title: 'ET3 Export',
              route: ROUTES.ET3_EXPORT_UTILITY,
              description: 'Management of ET3 export.',
            },
            {
              icon: 'import_export',
              title: 'NEMSIS/DHS Export',
              route: ROUTES.DHS_EXPORT_UTILITY,
              description: 'Management of NEMSIS to DHS export.',
            },
            {
              icon: 'import_export',
              title: 'Billing Export',
              route: ROUTES.EXPORT_LOG_BILLING,
              description: 'Billing export data log.',
            },
            {
              icon: 'import_export',
              title: 'DHS Export',
              route: ROUTES.EXPORT_LOG_DHS,
              description: 'DHS export data log.',
            },
            {
              icon: 'import_export',
              title: 'Unfiltered Export',
              route: ROUTES.EXPORT_LOG_UNFILTERED,
              description: 'Unfiltered export data log.',
              isAvailable() {
                return store.state.siteInfo.hasUnfilteredData;
              },
            },
          ],
        },
        {
          title: 'CAD',
          icon: 'mdi-car-emergency',
          items: [
            {
              icon: 'notification_important',
              title: 'CAD Monitoring',
              route: ROUTES.CAD_MONITORING,
              description: 'Status of CAD service.',
              isAvailable() {
                return store.state.siteInfo.cadMonitoringAllowed
                    && store.getters.ability.can('showCadMonitoring', 'navigation');
              },
            },
            {
              icon: 'horizontal_split',
              title: 'Recent CAD Data',
              route: ROUTES.LAST_IMPORTED_INCIDENTS,
              description: 'Review RAW data received from CAD service.',
            },
            {
              icon: 'mdi-table-arrow-right',
              title: 'NFIRS Export',
              route: ROUTES.NFIRS_EXPORT,
              description: 'NFIRS export service.',
            },
          ],
        },
        {
          title: '3rd Party Exports',
          icon: 'mdi-export',
          items: [
            {
              icon: 'import_export',
              title: 'OD MAP Exports',
              route: ROUTES.OD_MAP_EXPORTS,
              description: 'Export log of drugs suspected incidents.',
              isAvailable() {
                return store.state.siteInfo.odMapExportsAllowed;
              },
            },
            {
              icon: 'import_export',
              title: 'HDE Exports',
              route: ROUTES.HDE_EXPORTS,
              description: 'Export log of HDE export incidents.',
            },
          ],
        },
        {
          title: 'iOS',
          icon: 'mdi-apple',
          items: [
            {
              icon: 'device_unknown',
              title: 'First Run Devices',
              route: ROUTES.FIRST_RUN_DEVICES,
              description: 'List of new devices registration requests.',
            },
            {
              icon: 'tablet_mac',
              title: 'API Interactions Log',
              route: ROUTES.API_AUDIT,
              description:
                  "Review log of interactions between backend and it's clients: Mobile App, Web Sites.",
            },
            {
              icon: 'notifications',
              title: 'Push Notifications',
              route: ROUTES.PUSH_NOTIFICATIONS,
              description: 'Manage Push Notifications service.',
            },
          ],
        },
        {
          title: 'Miscellaneous',
          icon: 'mdi-tools',
          items: [
            {
              icon: 'flag',
              title: 'Flagged Users',
              route: ROUTES.FLAGGED_USERS,
              description: 'Review list of Flagged Users / Incidents.',
            },
            {
              icon: 'notes',
              title: 'Release Notes',
              route: ROUTES.RELEASE_NOTES,
              description:
                  'Provide information on the features and improvements in each release: iOS App, Template, Backend',
            },
            {
              icon: 'error',
              title: 'Errors Utility',
              route: ROUTES.ERRORS_UTILITY,
              description: 'List of new devices registration requests.',
            },
            {
              icon: 'bug_report',
              title: 'Client Bug Reports',
              description: 'Forwards to bugtracker for clients.',
              link: 'https://digitalems.atlassian.net/projects/MC/queues/custom/1',
              rule: ACL.SUPER_ADMIN,
            },
            {
              icon: 'print',
              title: 'Faxes',
              route: ROUTES.FAXES,
              description: 'Faxes.',
            },
          ],
        },
      ],
    },
  ];
}
