<template>
  <div>
    <v-card class="white elevation-3 ma-4">
      <incident-ap-forms-toolbar
          :filters.sync="filters"
          @clickSearch="search"
      />
    </v-card>

    <v-card class="ma-4">
      <v-tabs v-model="tabIndex">
        <v-tab
            v-for="(item, index) in tabItems"
            :key="item.name"
            :tab-value="index"
            active-class="primary--text"
            class="subheading text-capitalize tab-item"
        >
          {{ item.name }}
        </v-tab>
      </v-tabs>
      <v-divider />
      <v-tabs-items :value="tabIndex">
        <v-tab-item eager>
          <incident-ap-forms-table
              ref="incomplete"
              :completed="false"
              :filters="filters"
              @clickIncident="showEditApForm"
              @returnTotal="setIncompleteTotal"
          />
        </v-tab-item>
        <v-tab-item eager>
          <incident-ap-forms-table
              ref="completed"
              :completed="true"
              :filters="filters"
              @clickIncident="showEditApForm"
              @returnTotal="setCompletedTotal"
          />
        </v-tab-item>
      </v-tabs-items>
    </v-card>
    <edit-ap-form-popup
        ref="editApFormPopup"
        @saved="updateIncidentLists"
    />
  </div>
</template>

<script>
import { SET_TITLE } from '@/store/mutations';
import IncidentApFormsTable from '@/components/IncidentApForms/IncidentApFormsTable';
import IncidentApFormsToolbar from '@/components/IncidentApForms/IncidentApFormsToolbar';
import EditApFormPopup from '@/components/IncidentApForms/EditApFormPopup';

export default {
  components: { EditApFormPopup, IncidentApFormsToolbar, IncidentApFormsTable },

  data() {
    return {
      tabIndex: 0,
      incompleteTotal: 0,
      completeTotal: 0,
      filters: {
        rangeBy: 'incidentDate',
        dateRangeFrom: null,
        dateRangeTo: null,
        sequenceNumber: null,
        incidentNumber: null,
        apLastName: null,
        ptLastName: null,
      },
    };
  },
  computed: {
    tabItems() {
      return [
        {
          id: 'incomplete',
          name: `Incomplete Forms${this.incompleteTotal ? ` (${this.incompleteTotal})` : ''}`,
        },
        {
          id: 'completed',
          name: `Completed Forms${this.completeTotal ? ` (${this.completeTotal})` : ''}`,
        },
      ];
    },
  },

  watch: {
    filters: {
      handler() {
        this.search();
      },
      deep: true,
    },
  },

  async mounted() {
    this.$store.commit(SET_TITLE, 'Incident AP Forms');
  },

  methods: {
    /**
     * Fetch data for current view.
     */
    search() {
      this.$refs.incomplete.search();
      this.$refs.completed.search();
    },

    updateIncidentLists() {
      this.$refs.incomplete.fetchData();
      this.$refs.completed.fetchData();
    },

    setIncompleteTotal(total) {
      this.incompleteTotal = total;
    },

    setCompletedTotal(total) {
      this.completeTotal = total;
    },

    showEditApForm(incident) {
      this.$refs.editApFormPopup.show(incident.id, incident.sequenceNumber);
    },
  },
};
</script>
