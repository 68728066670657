<template>
  <v-app-bar
    v-if="displayHeader"
    id="appbar"
    :class="[$style.header, $style['app-toolbar']]"
    color="primary"
    dark
    fixed
    app
    dense
  >
    <v-toolbar-title>{{ title }}</v-toolbar-title>

    <v-spacer></v-spacer>

    <v-toolbar-items>
      <verify-contact-data-button
          v-if="user && (!user.phoneVerified || !user.emailVerified)
          && (
              siteInfo
              && !siteInfo.isImpersonated
              && siteInfo.clientSiteType !== clientSiteTypes.CE_ENTRY
              )"
      />

      <notification-menu
          v-if="this.$can('view', 'notificationBell')"
          :unread-count="user.notifications"
      />
      <v-menu
        left
        open-on-hover
        transition="slide-y-transition"
        max-width="365"
        attach="#appbar"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            text
            max-width="365"
            v-bind="attrs"
            v-on="on"
          >
            <div class="text-capitalize text-right pa-2">
              <div :class="[$style['truncate-text']]">
                {{ siteInfo.roleDescription }}
              </div>
              <div class="text-caption text-lowercase font-weight-medium">
                {{ user.firstName }}
              </div>
            </div>
            <v-icon size="32">account_circle</v-icon>
          </v-btn>
        </template>

        <v-list
          :class="[$style.menuContent, 'pa-0']"
          dense
        >
          <v-list-item
            class="text-right py-0"
            @click="openProfile()"
          >
            <v-list-item-content>
              <v-list-item-title v-html="siteInfo.roleDescription"></v-list-item-title>
              <v-list-item-subtitle
                :class="$style.subtitle"
                v-html="user.firstName"
              ></v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-icon class="ml-2">
              <v-icon size="32">account_circle</v-icon>
            </v-list-item-icon>
          </v-list-item>

          <v-list-item
            class="text-right pa-0"
            @click="logout()"
          >
            <v-list-item-content>
              <v-list-item-title v-html="'Logout'"></v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon class="mx-2">
              <v-icon size="32">exit_to_app</v-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar-items>

    <profile-popup
      ref="profileEditPopup"
      @saved="reloadUserProfile()"
    ></profile-popup>
  </v-app-bar>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import _ from 'lodash';
import auth from '@/api/auth';
import profile from '@/api/profile';
import { REINIT } from '@/store/actions';
import { SET_USER } from '@/store/mutations';
import VerifyContactDataButton from '@/components/Profile/VerifyContactDataButton';
import CLIENT_SITE_TYPES from '@/enums/clientSiteTypes';
import ProfilePopup from './Profile/ProfilePopup';
import NotificationMenu from './Notifications/NotificationMenu';

export default {
  name: 'Header',
  components: {
    VerifyContactDataButton,
    ProfilePopup,
    NotificationMenu,
  },
  data() {
    return {
      clientSiteTypes: CLIENT_SITE_TYPES,
    };
  },
  computed: {
    ...mapState(['siteInfo', 'user', 'title', 'displayHeader']),
  },
  methods: {
    ...mapMutations({
      setUser: SET_USER,
    }),
    ...mapActions([REINIT]),
    logout() {
      auth.logout().then(() => {
        this.REINIT();
      });
    },
    openProfile() {
      this.$refs.profileEditPopup.showEdit(_.clone(this.user));
    },
    async reloadUserProfile() {
      const user = await profile.fetchUser();
      this.setUser(user);
    },
  },
};
</script>

<style lang="scss" module>
.header div[role=menu] {
  top: 2px !important;
}

.subtitle {
  color: inherit !important;
  font-weight: 400 !important;
}
.app-toolbar {
  z-index: 110 !important;
}
.truncate-text {
  max-width: 285px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
